<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>

    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--13">
      <system-nav></system-nav>
      <section class="main-content">
        <section class="section">
          <div class="section__titles">
            <h4 class="section__title">Áreas de interés</h4>
            <button class="btn btn--primary btn--outline" @click.prevent="showNewAreaOfInterestDialog()">Nueva área de interés</button>
          </div>
          <div v-if="disabledExists" class="tabs">
            <div :class="`tab${status ? ' tab--active' : ''}`" @click.prevent="loadData(true)">Habilitadas</div>
            <div :class="`tab${!status ? ' tab--active' : ''}`" @click.prevent="loadData(false)">Deshabilitadas</div>
          </div>
          <div class="list">
            <div class="list__item" v-for="area in areas" :key="area.id">
              <div class="item__info">
                <p>{{ area.name }}</p>
              </div>
              <div class="item__actions">
                <DropdownMenu>
                  <template #button>
                    <button class="btn btn--icon btn--circle">
                      <img class="icon" src="@/assets/images/icons/ico_dots--bl.svg">
                    </button>
                  </template>
                  <template #options>
                    <a class="dropdown__option" href="#" @click.prevent="showEditAreaOfInterestDialog(area.id)">
                      Modificar
                    </a>
                    <a :class="['dropdown__option', status ? 'dropdown__option--danger' : '']" href="#" @click.prevent="changeStatus(area.id)">
                      {{ status ? 'Deshabilitar' : 'Habilitar' }}
                    </a>
                  </template>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>

  <!-- Nueva área de interés -->
  <modal-dialog v-if="newAreaOfInterestDialog">
    <template #title>Nueva área de interés</template>
    <template #description>Ingresa el nombre de la nueva área de interés</template>
    <template #content>
      <form class="form" @submit.prevent="save()">
        <div class="form__group">
          <label class="form__label" for="name">Nombre</label>
          <input class="form__input" id="name" type="name" v-model.trim="areaOfInterest.name" :disabled="submitting" required>
        </div>
        <div class="form__group">
          <label class="form__label" for="url">Imagen(url)</label>
          <input class="form__input" id="url" type="url" v-model.trim="areaOfInterest.url" :disabled="submitting" required>
        </div>
        <div class="form__actions">
          <button class="btn btn--primary btn--outline" type="button" @click.prevent="newAreaOfInterestDialog = false" :disabled="submitting">Cancelar</button>
          <button class="btn btn--primary" type="submit" :disabled="submitting">Crear</button>
        </div>
      </form>
    </template>
  </modal-dialog>

  <!-- Editar área de interés -->
  <modal-dialog v-if="editAreaOfInterestDialog">
    <template #title>Editar área de interés</template>
    <template #description>Modifica los campos del área de interés</template>
    <template #content>
      <form class="form" @submit.prevent="update()">
        <div class="form__group">
          <label class="form__label" for="name">Nombre</label>
          <input class="form__input" id="name" type="name" v-model.trim="areaOfInterest.name" :disabled="submitting" required>
        </div>
        <div class="form__group">
          <label class="form__label" for="url">Imagen(url)</label>
          <input class="form__input" id="url" type="url" v-model.trim="areaOfInterest.url" :disabled="submitting" required>
        </div>
        <div class="form__actions">
          <button class="btn btn--primary btn--outline" type="button" @click.prevent="editAreaOfInterestDialog = false" :disabled="submitting">Cancelar</button>
          <button class="btn btn--primary" type="submit" :disabled="submitting">Actualizar</button>
        </div>
      </form>
    </template>
  </modal-dialog>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import SystemNav from '@/components/SystemNav.vue'
import ModalDialog from '@/components/ModalDialog.vue'
import DropdownMenu from '@/components/DropdownMenu.vue'

export default {
  components: { 
    SideNav,
    SystemNav,
    ModalDialog,
    DropdownMenu
  },
  name: 'AreaOfInterests',
  data () {
    return {
      areas: [],
      newAreaOfInterestDialog: false,
      editAreaOfInterestDialog: false,
      areaOfInterest: {
        id: '',
        name: '',
        url: ''
      },
      submitting: false,
      status: true,
      disabledExists: false
    }
  },
  async created() {
    this.disabledExists = (await this.$axios.get(`areas/total/false`)).data[1] > 0
    await this.loadData(true)
  },
  methods: {
    showNewAreaOfInterestDialog() {
      this.areaOfInterest.name = ""
      this.areaOfInterest.url = ""
      this.newAreaOfInterestDialog = true
    },
    async showEditAreaOfInterestDialog(id){
      this.areaOfInterest = (await this.$axios.get(`areas/${id}`)).data
      this.editAreaOfInterestDialog = true
    },
    async loadData(status){
      this.status = status
      this.areas = []
      this.areas = (await this.$axios.get(`areas/${this.status}/1`)).data
    },
    async save(){
      this.submitting = true
      const res = await this.$axios.post(`areas/create`, this.areaOfInterest);
      this.submitting =  false
      if(res.status == 200){
        this.$toast.success(`Área de interés creada con éxito`);
        await this.loadData(this.status)
        this.newAreaOfInterestDialog = false;
      }
      else
        this.$toast.error(`Ocurrió un error al crear el Área de interés`);
    },
    async update(){
      this.submitting = true
      const res = await this.$axios.post(`areas/update/${this.areaOfInterest.id}`, this.areaOfInterest);
      this.submitting =  false
      if(res.status == 200){
        this.$toast.success(`Área de interés modificada con éxito`);
        await this.loadData(this.status)
        this.editAreaOfInterestDialog = false;
      }
      else
        this.$toast.error(`Ocurrió un error al modificar el Área de interés`);
    },
    async changeStatus(id){
      if(confirm(this.status ? '¿Desea deshabilitar el elemento seleccionado?' : '¿Desea habilitar el elemento seleccionado?')){
        const res = await this.$axios.post(`areas/status/${id}`);
        if(res.status == 200){
          this.disabledExists = (await this.$axios.get(`areas/total/false`)).data[1] > 0
          this.$toast.success(`Área de interés modificada con éxito`);
          if(!this.disabledExists) 
            await this.loadData(true)
          else
            await this.loadData(this.status)
        }
      }
    }
  }
}
</script>